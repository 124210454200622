<template>
  <component :is="componentToShow"
             :title="title"
             :copy="copy"
             :cta="cta"
             :logo="logo"
             :background-image="backgroundImage"
             :background-image-mobile="backgroundImageMobile"
             :background-gradient="backgroundGradient"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { CtaType } from '../../types/button';
import type { CardBaseType } from '../../types/cards';

interface CardBaseProps {
  title: string;
  copy?: string;
  cta?: CtaType;
  type?: CardBaseType;
  logo?: string;
  backgroundImage?: string;
  backgroundImageMobile?: string;
  backgroundGradient?: string;
}

const props = withDefaults(defineProps<CardBaseProps>(), {
  type: 'image',
  backgroundImage: '',
  backgroundGradient: '',
  backgroundImageMobile: '',
  cta: false,
  copy: '',
  class: '',
  logo: ''
});

/**
 * Component to render
 *
 * In order to render NuxtLink as a dynamic component, we need to use
 * the resolveComponent function.
 */
const componentToShow = computed(() => {
  if (props.type === 'news') {
    return 'CardNews';
  }

  if (props.type === 'product') {
    return 'CardProduct';
  }

  if (props.type === 'product-small') {
    return 'CardProductSmall';
  }

  return 'CardImage';
});
</script>
